import * as React from "react"
import {graphql, Link} from "gatsby"
import Seo from "../../components/seo";
import * as styles from "./404.module.scss";
import Layout from "../../components/layout/layout";

const NotFoundPage = ({data}) => {
  return (
    <Layout contactInfo={data?.api?.contactInfo}>
      <Seo allowIndexing={false} title="Stránka nenalezena" />
      <div className={styles.container}>
        <h1>Stránka nebyla nalezena</h1>
        <Link to="/">
          <button className="primary"><span>&larr;</span> zpět na hlavní stránku</button>
        </Link>
        <div className={styles.info}>
          <i className="far fa-info-circle"/>
          <p>Toto se stává, pokud se snažíte dostat na URL adresu, která na této stránce neexistuje.</p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
    query {
        api {
            contactInfo:contactSingleton {
                email
                phone
                name
            }
        }
    }
`;

export default NotFoundPage;
